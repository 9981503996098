import { Headers } from "@angular/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { environment } from '../environments/environment';
import { timeStamp } from "console";

@Injectable()
export class HttpService {
    http: HttpClient;
    headers: HttpHeaders;

    authenticationUrl: string = "https://api.trumpsrocket.com";
    transactionUrl: string = "https://api.trumpsrocket.com";
    // authenticationUrl: string = "http://localhost:8001";
    // transactionUrl: string = "http://localhost:8001";


    token: string;
    urlAnterior: string;

    instanceOfModal;
    typeOfError;
    detailError;

    constructor(http: HttpClient, private router: Router) {
        this.http = http;
        this.headers = new HttpHeaders().set('Content-Type', 'application/json');
        console.log(this.headers)

        // if (environment.production) {
        //     this.transactionUrl = "https://anura.api.protocolbuilders.com";
        //     this.authenticationUrl = "https://anura.api.protocolbuilders.com";
        // }

        // this.transactionUrl = "https://anura.api.protocolbuilders.com";
        // this.authenticationUrl = "https://anura.api.protocolbuilders.com";

    }

    appendAuthorization() {
        var user = JSON.parse(localStorage.getItem('user'))
        this.headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + user.access_code);
    }


    handleAPIError(error: any): Promise<any> {
        console.error('handleError - um Erro ocorreu', error);
        if (error.status == 401) {
            console.log("ERROR STATUS 401")
            // this.unauthorizedError();
        }
        if (error.status == 503) {
            this.typeOfError = 503;
            this.instanceOfModal.show();
        }
        if (error.status == 423) {
            this.typeOfError = 423;
            this.instanceOfModal.show();
        }
        if (error.status == 400) {
            this.typeOfError = 400;
            console.log(error)
            this.detailError = error.error.detail;
            this.instanceOfModal.show();
        }
        if (error.status == 409) {
            this.typeOfError = 409;
            this.detailError = error.error.detail;
            this.instanceOfModal.show();
        }

        if (error.status == 500 || error.status == 0) {
            this.typeOfError = 500;
            this.instanceOfModal.show();
        }
        return Promise.reject(error.message || error);
    }

    unauthorizedError() {
        // localStorage.removeItem('user')
        // window.location.reload();
    }

    getDetailError() {
        return this.detailError
    }

    putInstaceOfModal(instance) {
        this.instanceOfModal = instance;
    }
}