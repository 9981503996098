import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OldLayoutComponent } from './pages/old-layout/old-layout.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './guards/auth.guard';


const landingRoutes: Routes =
    [
        {
            path: '', component: OldLayoutComponent,
            children: [
                {
                    path: '',
                    redirectTo: 'home',
                    pathMatch: 'full',
                },
                {
                    path: 'home', component: SettingsComponent,
                    data: {
                        meta: {
                            title: 'Trumps Rocket',
                            override: true,
                            description: '',

                        }
                    }
                },
                {
                    path: 'login', component: LoginComponent,
                    data: {
                        meta: {
                            title: 'Trumps Rocket',
                            override: true,
                            description: '',

                        }
                    }
                },
                {
                    path: '**', component: SettingsComponent
                }
            ]
        }
    ]





@NgModule({
    imports: [RouterModule.forRoot(landingRoutes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }