import { Component, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { Web3Service } from '../../service/web3.service';
import Util from '../../util'
import { ContractWallet } from '../../model/contract-wallet';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnDestroy {
    setIntervalEvent;
    price = new ContractWallet();
    staticUtil;
    gemObj;
    web3;

    constructor(private web3Service: Web3Service) {
        this.gemObj = new ContractWallet();
    }

    async ngAfterViewInit() {
        this.gemObj.exchange = "v2";
        this.gemObj.hasBurn = 1
        this.gemObj.tokenDecimal = 9
        this.gemObj.burnAddress = "0x000000000000000000000000000000000000dead"
        this.gemObj.contractAddress = "0xe3d478fe8e8f55f7e9b2b55cf25868edc9f924d8"
        // this.web3 = await this.web3Service.getWeb3Service();
        // this.update()
        // this.setIntervalEvent = setInterval(() => {
        //     this.update();
        // }, 30000)
    }

    treatPrice(price) {
        if (environment.production) {
            return price.toFixed(4);
        }
        else {
            return 0
        }
    }

    update() {
        this.web3Service.findMarketCap(this.gemObj)
        console.log("GEMRESULT", this.gemObj)
    }

    ngOnDestroy() {
        clearInterval(this.setIntervalEvent)
    }

}
