import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import 'reflect-metadata';

import { AppRoutingModule } from './app-routing.module';
import { MetaModule } from '@ngx-meta/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgApexchartsModule } from 'ng-apexcharts';

import { HttpService } from './http.service';
import { TooltipModule } from 'ng2-tooltip-directive';

import { AppComponent } from './app.component';;
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { OldLayoutComponent } from './pages/old-layout/old-layout.component';;
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AuthGuard } from './guards/auth.guard';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';

import { Web3Service } from './service/web3.service';
import { ConnectWalletComponent } from './component/connect-wallet/connect-wallet.component';
import { AccountService } from './service/account.service';
import { ModalBorderComponent } from './component/modal-border/modal-border.component';
import { PlayComponent } from './pages/play/play.component';
import { LocalStorageService } from './service/localStorage.service';
import { SettingsComponent } from './pages/settings/settings.component';
import { UtilsService } from './service/utils.service';
import { OnChainService } from './service/onchain.service';
import { ScrollingModalComponent } from './component/scrolling-modal/scrolling-modal.component';
import { SweetModalComponent } from './component/sweet-modal/sweet-modal.component';
import { LoginComponent } from './pages/login/login.component';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    OldLayoutComponent,
    HeaderComponent,
    FooterComponent,
    ConnectWalletComponent,
    ModalBorderComponent,
    PlayComponent,
    SettingsComponent,
    ScrollingModalComponent,
    SweetModalComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule, AppRoutingModule, HttpClientModule, HttpModule, FormsModule, ReactiveFormsModule, NgApexchartsModule,
    MetaModule.forRoot(), TooltipModule, InfiniteScrollModule, NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 90,
      maxPercent: 100,
      showSubtitle: false,
      showUnits: false,
      showInnerStroke: false,
      titleFontSize: "80",
      responsive: true
    })
  ],
  providers: [HttpService, AuthGuard, Web3Service, AccountService, LocalStorageService, UtilsService, OnChainService, DatePipe
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
