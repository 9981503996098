export class ContractWallet {
    contractAddress;
    tokenName;
    tokenSymbol;
    balance;
    tokenDecimal;
    priceV1;
    priceV2;
    realPrice;
    oldPrice;
    valueInUsd;
    valueInBnb;
    exchange;
    oldBalance = 0;
    marketCap;
    marketCapString;
    burnAddress;
    hasBurn;
    totalSupply;
    circulatingSupply;
    totalBurn;
    totalBurnString;
    circulatingSupplyString;
    uniswapPairAddress;
    hasLessThanOneBNBInLP = false; //deprecated delete later
    checkedLP; //deprecated delete later
    checkedBNBLpAmount;
    hasLessThanOneBNBInBothLP;
    firstPrice;
    bnbCount;
}