import { Injectable } from '@angular/core';

import { HttpService } from '../http.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { User } from '../model/user';

@Injectable()
export class AccountService {

    constructor(private httpUrl: HttpService, private router: Router) {

    }

    getProfile(secret) {
        var path = "profile"
        let bodyObj = { "access_code": secret };
        var bodyString = JSON.stringify(bodyObj)
        const url = `${this.httpUrl.authenticationUrl}/${path}`;
        console.log(url, bodyString)
        return this.httpUrl.http
            .post<any>(url, bodyString, { headers: this.httpUrl.headers })
            .pipe(map(response => response))
            .pipe(
                catchError(err => this.handleError(err))
            )
    }
    /**
     * This function return a new Nonce. When the account is not registered on database creates a new user.
     * @param address 
     * @returns nonce and address
     */
    getNewNonce(walletAddress) {
        var path = "login"
        let bodyObj = { "wallet_address": walletAddress };
        var bodyString = JSON.stringify(bodyObj)
        const url = `${this.httpUrl.authenticationUrl}/${path}`;
        console.log(url, bodyString)
        return this.httpUrl.http
            .post<User>(url, bodyString, { headers: this.httpUrl.headers })
            .pipe(map(response => response))
            .pipe(
                catchError(err => this.httpUrl.handleAPIError(err))
            )
    }

    /**
     * Back-end should check if its everything alright with signature and return a full user object(balance, jwt and more)
     * @param signatureObj 
     * @returns user
     */
    sendNonceSignature(walletAddress, signature, hashMessage, access_code) {
        var path = "authenticate"
        let bodyObj = { "signature": signature, "message_hash": hashMessage, "wallet_address": walletAddress, "access_code": access_code };
        var bodyString = JSON.stringify(bodyObj)
        const url = `${this.httpUrl.authenticationUrl}/${path}`;
        return this.httpUrl.http
            .post<User>(url, bodyString, { headers: this.httpUrl.headers })
            .pipe(map(response => response))
            .pipe(
                catchError(err => this.httpUrl.handleAPIError(err))
            )
    }

    getBalanceOfUser() {
        var path = "balance"
        const url = `${this.httpUrl.authenticationUrl}/${path}`;

        this.httpUrl.appendAuthorization();

        return this.httpUrl.http
            .get<User>(url, { headers: this.httpUrl.headers })
            .pipe(map(response => response))
            .pipe(
                catchError(err => this.httpUrl.handleAPIError(err))
            )
    }

    getAllTransactions() {
        var root = "settings"
        var path = "get"
        const url = `${this.httpUrl.transactionUrl}/${root}/${path}`;

        this.httpUrl.appendAuthorization();

        return this.httpUrl.http
            .get<[]>(url, { headers: this.httpUrl.headers })
            .pipe(map(response => response))
            .pipe(
                catchError(err => this.httpUrl.handleAPIError(err))
            )
    }

    getConfig() {
        var root = "config"

        const url = `${this.httpUrl.transactionUrl}/${root}`;

        return this.httpUrl.http
            .get<any>(url, { headers: this.httpUrl.headers })
            .pipe(map(response => response))
            .pipe(
                catchError(err => this.httpUrl.handleAPIError(err))
            )
    }

    handleError(error: any): Promise<any> {
        console.log(error)
        return Promise.resolve(null);
    }

}