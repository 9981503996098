import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Web3Service } from '../../service/web3.service';
import Util from '../../util'

declare var window: any;
declare var Web3: any;

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent {

    expeditions;
    staticUtil

    constructor(private web3Service: Web3Service, private router: Router) {
        this.checkLogged();
    }


    checkLogged() {
        if (localStorage.getItem('user')) {
            if (JSON.parse(localStorage.getItem('user')).session_token != undefined) {
                this.router.navigate(['/heroes'])
            }
        }
    }

    sendToLink() {
        window.open('https://t.me/trumprocket_cashier_bot');
      }
}