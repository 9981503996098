import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { User } from '../../model/user';
import { Web3Service } from '../../service/web3.service';

declare var window: any;
declare var Web3: any;

@Component({
    selector: 'play',
    templateUrl: 'play.component.html',
    styleUrls: ['./play.component.css']
})

export class PlayComponent {
    
    constructor() {

    }
}