import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {
    currentUser;

    constructor() {
        this.getCurrentUserOnLocalStorage();
    }

    getCurrentUserOnLocalStorage() {
        if (localStorage.getItem('user')) {
            this.currentUser = JSON.parse(localStorage.getItem('user'))
        } else {
            this.currentUser = undefined;
        }
    }

    saveUserLocalStorage(user) {
        this.currentUser = user;
        let userString = JSON.stringify(user);
        localStorage.setItem('user', userString);
    }

    removeUser() {
        this.currentUser = undefined;
        localStorage.removeItem('user');
    }

    changeUserBalance(newBalance) {
        this.currentUser.balance = newBalance;
        this.saveUserLocalStorage(this.currentUser);
    }

}