import { Component, ViewChild, NgZone, Renderer, ElementRef } from '@angular/core';
import { Http } from '@angular/http';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'
import { Location, PopStateEvent } from "@angular/common";
import * as $ from 'jquery';

@Component({
  selector: 'old-layout',
  templateUrl: './old-layout.component.html',
  styleUrls: ['./old-layout.component.css']
})
export class OldLayoutComponent {

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  rocketzAccounts;
  script;


  constructor() {

  }

}
