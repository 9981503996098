import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { MetaService } from '@ngx-meta/core';
import { pipe } from 'rxjs';
import { Web3Service } from '../../service/web3.service';
import { AccountService } from '../../service/account.service';
import { User } from '../../model/user';
import { LocalStorageService } from '../../service/localStorage.service';
import { Router } from '@angular/router';
import { ModalBorderComponent } from '../modal-border/modal-border.component';

declare var window: any;
declare var Web3: any;

@Component({
    selector: 'connect-wallet',
    templateUrl: 'connect-wallet.component.html',
    styleUrls: ['./connect-wallet.component.css']
})

export class ConnectWalletComponent {
    @ViewChild('modal1', { static: true }) loginModal: ModalBorderComponent;

    web3;
    unableToConnect;

    //erase later
    walletAddress;
    walletConnected;

    mockedUUID = "b9f41c3d-cfef-4e24-b6af-75deaf536163";
    signature;
    signatureSent;
    hashMessage;

    account;

    getBalanceTimeout;
    firstTimeGetBalance;

    constructor(private web3Service: Web3Service, private router: Router, private accountService: AccountService,
        private localStorageService: LocalStorageService) {

    }

    async ngAfterViewInit() {
        this.web3 = this.web3Service.getWeb3Service()
        await this.web3Service.startRouters();
    }

    checkIfHasUser() {
        if (this.localStorageService.currentUser != undefined && this.localStorageService.currentUser.wallet_address != '') {
            return true;
        }
    }

    async connectMetaMask() {
        this.unableToConnect = false;
        this.web3 = this.web3Service.getWeb3Service();
        if (window.ethereum) {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
            console.log(accounts)
            window.web3 = new Web3(window.ethereum);
            console.log(window.web3);
            if (this.web3.utils.isAddress(accounts[0])) {
                this.saveWalletConnect(accounts[0])
                this.startSignInProcess();
                return;
            }
        }
        this.unableToConnect = true;
    }


    // async connectMetaMask() {
    //     console.log("Connect Wallet")
    //     try {
    //         this.unableToConnect = false;
    //         this.web3 = this.web3Service.getWeb3Service();
    //         let provider = window.solana
    //         if (!provider.isPhantom) {
    //             alert("Phantom wallet not found!")
    //             this.unableToConnect = true;
    //             return;
    //         }
    //         let accounts = await window.solana.connect(); // Requests connection
    //         console.log("Connected with Public Key:", accounts.publicKey.toString())
    //         console.log(accounts)
    //         window.web3 = new Web3(window.ethereum);
    //         console.log(window.web3);
    //         this.saveWalletConnect(accounts.publicKey.toString())
    //         this.startSignInProcess();
    //         return;
    //     } catch {
    //         this.unableToConnect = true;
    //         console.log("shit catch")
    //     }

    // }

    saveWalletConnect(address) {
        this.walletAddress = address;
        this.walletConnected = true;
        //save on localStorage
    }

    async getSecretNonceOnServer() {
        console.log("getSecretNonceOnServer")
        var nonce = this.localStorageService.currentUser.access_code
        return nonce;
    }


    async startSignInProcess() {
        this.walletAddress = this.web3.utils.toChecksumAddress(this.walletAddress)
        var nonce = await this.getSecretNonceOnServer();
        var result = await window.ethereum.request({
            method: 'personal_sign',
            params: [
                this.web3.utils.fromUtf8(`I am signing to Trump Rockets Game. Please, don't share this secret. \n Secret: ${nonce}`),
                this.walletAddress
            ],
        })
        this.signature = result;
        this.signatureSent = true;
        this.sendMetamaskSignatureToTheServer(nonce, this.signature);
    }

    sendMetamaskSignatureToTheServer(nonce, signature) {
        this.signature = signature;
        this.signatureSent = true;
        this.hashMessage = this.web3.eth.accounts.hashMessage(this.web3.utils.fromUtf8(`I am signing to Trump Rockets Game. Please, don't share this secret. \n Secret: ${nonce}`))
        this.accountService.sendNonceSignature(this.walletAddress, signature, this.hashMessage, this.localStorageService.currentUser.access_code).subscribe(res => {
            if (res != null) {
                this.account = res;
                this.loginModal.hide();
                this.localStorageService.saveUserLocalStorage(res);
                window.location.reload()
            }

        })
    }
    showCutAddress() {
        let address = this.localStorageService.currentUser.wallet_address
        address = address.slice(0, 7);
        return address.toLowerCase() + "..."
    }

    ngOnDestroy() {
        clearInterval(this.getBalanceTimeout)
    }

}