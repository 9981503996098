import { Injectable, NgProbeToken } from '@angular/core';

import { HttpService } from '../http.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';
import Util from '../util'
import '../../assets/js/numeral.min.js'
import * as $ from 'jquery';
import { isBuffer } from 'util';
import { SSL_OP_ALLOW_UNSAFE_LEGACY_RENEGOTIATION } from 'constants';

declare var Web3: any;
declare var Web3Eth: any;
declare var Web3HttpProvider: any;
declare var BigNumber: any;
declare var numeral: any;
const abiDecoder = require('abi-decoder');
var masterChefAbi = require('../../assets/abi/masterchef.json');
declare let window: any;

@Injectable()
export class Web3Service {
    web3 = null;
    masterChefAddressV1 = '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F'; //router Address
    masterChefAddressV2 = '0x10ED43C718714eb63d5aA57B78B54704E256024E'; //router Address
    factoryAddressV2 = "0xca143ce32fe78f1f7019d7d551a6402fc5350c73";
    factoryAddressV1 = "0xbcfccbde45ce874adcb698cc183debcf17952812";
    routerContractV1 = null;
    routerContractV2 = null;
    factoryContractV1 = null;
    factoryContractV2 = null;
    WBNB = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
    BUSD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
    USDT = "0x55d398326f99059fF775485246999027B3197955"
    gemProtocolContract = "0xe3D478FE8E8f55F7e9b2b55cF25868EDc9F924d8";
    BUSDPrice;
    BNBPrice;
    pastEventBlock = 0;
    pastEventsHashs;
    burnsAddress = ["0x0000000000000000000000000000000000000001",
        "0x0000000000000000000000000000000000000000",
        "0x000000000000000000000000000000000000dead"]
    currentWeb3Provider;
    currentRouterV1;
    currentRouterV2;
    currentFactoryV1;
    currentFactoryV2;

    constructor() {
        abiDecoder.addABI(masterChefAbi)
    }

    async addToMetamask(poke) {
        var url = "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/" + poke.contractAddress + "/logo.png"
        var wasAdded;
        var request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.send();
        request.onload = function () {
            if (request.status != 200) {
                wasAdded = window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20', // Initially only supports ERC20, but eventually more!
                        options: {
                            address: poke.contractAddress, // The address that the token is at.
                            symbol: poke.tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                            decimals: poke.tokenDecimal, // The number of decimals in the token
                        },
                    },
                });
            } else {
                wasAdded = window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20', // Initially only supports ERC20, but eventually more!
                        options: {
                            address: poke.contractAddress, // The address that the token is at.
                            symbol: poke.tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                            decimals: poke.tokenDecimal, // The number of decimals in the token
                            image: url, // A string url of the token logo
                        },
                    },
                });
            }
        }
    }

    async loadEthereum() {
        this.web3 = null;
        window['web3'] = null;
        this.masterChefAddressV1 = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'; //router Address
        this.masterChefAddressV2 = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'; //router Address 0xE592427A0AEce92De3Edee1F18E0157C05861564
        this.factoryAddressV2 = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f"; //0x1F98431c8aD98523631AE4a59f267346ea31F984
        this.factoryAddressV1 = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
        this.WBNB = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
        this.USDT = "0xdAC17F958D2ee523a2206206994597C13D831ec7"
        this.BUSD = "0xdAC17F958D2ee523a2206206994597C13D831ec7"
        this.getETHWeb3Service();
        await this.startFactories();
        await this.startRouters();
    }

    async loadBinance() {
        this.web3 = null;
        window['web3'] = null;
        this.masterChefAddressV1 = '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F'; //router Address
        this.masterChefAddressV2 = '0x10ED43C718714eb63d5aA57B78B54704E256024E'; //router Address
        this.factoryAddressV2 = "0xca143ce32fe78f1f7019d7d551a6402fc5350c73";
        this.factoryAddressV1 = "0xbcfccbde45ce874adcb698cc183debcf17952812";
        this.WBNB = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
        this.USDT = "0x55d398326f99059fF775485246999027B3197955"
        this.BUSD = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
        this.getWeb3Service()
        await this.startFactories();
        await this.startRouters();
    }

    resetPastEventBlock() {
        this.pastEventBlock = 0;
        this.pastEventsHashs = new Set()
    }
    getWeb3Service() {
        if (this.web3 == null || this.currentWeb3Provider == "bsc") {
            this.currentWeb3Provider = "bsc"
            window['web3'] = new Web3(new Web3HttpProvider('https://cloudflare-eth.com/'));
            this.web3 = window['web3']
        }
        return this.web3;
    }

    getETHWeb3Service() {
        console.log(this.web3)
        if (this.web3 == null || this.currentWeb3Provider == "eth") {
            this.currentWeb3Provider = "eth"
            window['web3'] = new Web3(new Web3HttpProvider('https://cloudflare-eth.com/'));
            this.web3 = window['web3']
        }
        return this.web3;
    }

    startRouters() {
        this.getRouterContractV1();
        this.getRouterContractV2();
    }

    startFactories() {
        this.getFactoryContractV1();
        this.getFactoryContractV2();
    }

    getContractAddressBasicInfo = async (contractAddress, obj) => {
        var contractObject = await new this.web3.eth.Contract(this.getContractAbi(), contractAddress);
        obj.tokenName = await contractObject.methods.name().call();
        obj.tokenDecimal = await contractObject.methods.decimals().call();
        obj.tokenDecimal = parseInt(obj.tokenDecimal);
        obj.tokenSymbol = await contractObject.methods.symbol().call();
        try {
            obj.uniswapPairAddress = await contractObject.methods.uniswapV2Pair().call();
        } catch (error) {
            console.log(error)
        }
        return obj;
    }

    getBalanceOfAddress = async (contractObject, address, obj) => {
        try {
            const balance = await contractObject.methods.balanceOf(address).call();
            const balanceBigNumber = BigNumber(balance).div(10 ** obj.tokenDecimal).toNumber();
            if (balance == 0) {
                obj.balance = -1;
                return;
            }
            console.log("todosjuntos", obj, balanceBigNumber)
            obj.balance = balanceBigNumber;
        } catch (error) {

        }
    };

    getBusdPrice = async (routerContract) => {
        try {
            if (this.BUSD == "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56") {
                this.BUSDPrice = await this.getPriceData([this.BUSD, this.WBNB], 18, routerContract);
            } else {
                this.BUSDPrice = await this.getPriceData([this.BUSD, this.WBNB], 6, routerContract);
            }
            this.BNBPrice = 1 / this.BUSDPrice;
            return this.BUSDPrice;
        } catch (error) {
            console.log("BUSDPRICEERROR", error)
        }
    }

    async findMarketCap(poke, rate = 1) {
        poke.executed = true;
        let contractObject = await new this.web3.eth.Contract(this.getContractAbi(), poke.contractAddress);
        let routerContract;
        if (poke.exchange == "v1") {
            routerContract = await new this.web3.eth.Contract(this.getRouterAbi(), this.masterChefAddressV1);
        } else {
            routerContract = await new this.web3.eth.Contract(this.getRouterAbi(), this.masterChefAddressV2);
        }

        console.log(contractObject);
        console.log(routerContract);
        poke.circulatingSupply = await this.getSupply(contractObject, poke);

        if (poke.hasBurn == 1) {
            poke.totalBurn = await this.getBurn(contractObject, poke);
        }

        let busdQuote = await this.getPriceData([this.BUSD, this.WBNB], 18, routerContract);
        let tokenQuote = await this.getPriceData([poke.contractAddress, this.WBNB], poke.tokenDecimal, routerContract);
        if (busdQuote && tokenQuote && poke.circulatingSupply) {
            let realPrice = tokenQuote / busdQuote;
            poke.price = realPrice * rate
            var difCap = poke.circulatingSupply - poke.totalBurn;
            let marketCap = difCap * poke.price;
            poke.marketCap = marketCap;
            // poke.marketCapString = Util.nFormatter(marketCap, 2)
            poke.marketCapString = numeral(marketCap).format('0,0')
            poke.circulatingSupply = difCap
            poke.circulatingSupplyString = numeral(difCap).format('0,0')
            poke.totalBurnString = numeral(poke.totalBurn).format('0,0')
            return Util.nFormatter(marketCap, 2)
        }
    }

    getSupply = async (contractObject, obj) => {
        const supply = await contractObject.methods.totalSupply().call();
        const fsupply = BigNumber(supply)
            .div(10 ** obj.tokenDecimal)
            .toNumber();
        return fsupply;
    };

    getBurn = async (contractObject, obj) => {
        obj.burnAddress = this.web3.utils.toChecksumAddress(obj.burnAddress)
        const burn = await contractObject.methods.balanceOf(obj.burnAddress).call();
        const fburn = BigNumber(burn)
            .div(10 ** obj.tokenDecimal)
            .toNumber();
        return fburn;
    };

    getFullBurn = async (contractObject, obj) => {
        var totalBurned = 0;
        var currentBurnAddress;
        for (var i in this.burnsAddress) {
            currentBurnAddress = this.web3.utils.toChecksumAddress(this.burnsAddress[i])
            const burn = await contractObject.methods.balanceOf(currentBurnAddress).call();
            const fburn = BigNumber(burn)
                .div(10 ** obj.tokenDecimal)
                .toNumber();
            totalBurned += fburn;
        }
        return totalBurned;


    };

    getPriceData = async (addresses, decimals, routerContract) => {
        let realPrice = null;
        const result1 = await routerContract.methods.getAmountsOut(new BigNumber((10 ** decimals).toString()).toString(), addresses).call();
        realPrice = new BigNumber(result1[1]).div(10 ** 18).toNumber();
        return realPrice;
    }

    getObjectPriceData = async (type, obj, addresses, routerContract) => {
        let realPrice = null;
        const result1 = await routerContract.methods.getAmountsOut(new BigNumber((10 ** obj.tokenDecimal).toString()).toString(), addresses).call();
        realPrice = new BigNumber(result1[1]).div(10 ** 18).toNumber();
        if (type == 'v1') {
            obj.priceV1 = realPrice;
        } else {
            obj.priceV2 = realPrice;
        }
    }

    getReservesInBNB = async (pairRouterAddress) => {
        let uniswapV2PairRouter = await new this.web3.eth.Contract(this.getUniSwapABI(), pairRouterAddress);
        let token0 = await uniswapV2PairRouter.methods.token0().call();
        let token1 = await uniswapV2PairRouter.methods.token1().call();
        let reserves = await uniswapV2PairRouter.methods.getReserves().call();
        let totalBNB = 0;
        if (token0 == this.WBNB) {
            totalBNB = this.web3.utils.fromWei(reserves._reserve0)
        }
        if (token1 == this.WBNB) {
            totalBNB = this.web3.utils.fromWei(reserves._reserve1)
        }
        return totalBNB;

    }

    getLPSize = async (obj) => {
        obj.checkedBNBLpAmount = true;
        try {
            await this.getFactoryContractV1();
            await this.getFactoryContractV2();
            let pairV1 = await this.factoryContractV1.methods.getPair(this.WBNB, obj.contractAddress).call();
            let pairV2 = await this.factoryContractV2.methods.getPair(this.WBNB, obj.contractAddress).call();
            let totalBNB = 0;
            let totalBNBv1 = this.web3.utils.fromWei("0")
            let totalBNBv2 = this.web3.utils.fromWei("0")

            if (pairV1 != "0x0000000000000000000000000000000000000000") {
                totalBNBv1 = await this.getReservesInBNB(pairV1);
                if (obj.contractAddress == "0xB27ADAfFB9fEa1801459a1a81B17218288c097cc")
                    console.log("totalBNBv1 " + obj.contractAddress, totalBNBv1)
            }
            if (pairV2 != "0x0000000000000000000000000000000000000000") {
                totalBNBv2 = await this.getReservesInBNB(pairV2);
                if (obj.contractAddress == "0xB27ADAfFB9fEa1801459a1a81B17218288c097cc")
                    console.log("totalBNBv2 " + obj.contractAddress, totalBNBv2)
            }
            if (parseInt(totalBNBv1) > parseInt(totalBNBv2)) {
                if (obj.contractAddress == "0xB27ADAfFB9fEa1801459a1a81B17218288c097cc")
                    console.log("deu v1")
                totalBNB = totalBNBv1;
                obj.exchange = "v1"
                obj.bnbCount = parseInt(totalBNBv2)
                obj.uniswapPairAddress = pairV1
            } else {
                if (obj.contractAddress == "0xB27ADAfFB9fEa1801459a1a81B17218288c097cc")
                    console.log("deu v2")
                totalBNB = totalBNBv2;
                obj.exchange = "v2"
                obj.bnbCount = parseInt(totalBNBv2)
                obj.uniswapPairAddress = pairV2
            }
            if (obj.contractAddress == "0xB27ADAfFB9fEa1801459a1a81B17218288c097cc")
                console.log(totalBNB)
            if (totalBNB < this.web3.utils.fromWei("1000000000000000000")) {
                obj.hasLessThanOneBNBInBothLP = true;
            }
        } catch (error) {

        }
    }

    getBNBPrice(obj) {
        obj.realPrice = this.BNBPrice;
        obj.priceV1 = this.BNBPrice;
        obj.priceV2 = this.BNBPrice;
        obj.oldPrice = this.BNBPrice

        if (obj.firstPrice == null || obj.firstPrice == undefined || obj.firstPrice == 0) {
            obj.firstPrice = obj.priceV2;
        }
        obj.valueInUsd = obj.balance * obj.priceV2
        obj.valueInBnb = obj.valueInUsd / this.BNBPrice
    }

    async getBNBGroupedBy(obj) {
        obj.groupedByValueInUsd = obj.groupedBalance * obj.priceV2
        obj.groupedByValueInBnb = obj.groupedByValueInUsd / this.BNBPrice
    }



    getFullPriceData = async (obj, addresses, addAnimation = null, removeAllAnimation = null) => {
        await this.getBusdPrice(this.routerContractV2);

        if (obj.contractAddress == this.WBNB || obj.tokenName == "Ethereum") {
            this.getBNBPrice(obj);

            if (addAnimation != null) {
                addAnimation(obj)
            }
            if (removeAllAnimation != null) {
                removeAllAnimation(obj)
            }
            return
        }
        // If didnt checked LP size yet, get the totalBNB
        if (!obj.checkedBNBLpAmount) {
            await this.getLPSize(obj);
        }
        // // If the size is too small, just dont get the price data
        if (obj.hasLessThanOneBNBInBothLP) {
            obj.oldPrice = 0;
            obj.valueInUsd = 0;
            obj.valueInBnb = 0;
            obj.realPrice = 0;
            obj.priceV1 = 0;
            obj.priceV2 = 0;
            obj.firstPrice = 0;
            obj.exchange = "v2"
            obj.hasLessThanOneBNBInBothLP = true;
            obj.checkedLP = true;
            return;
        }

        let tokenQuoteV1 = 0;
        let tokenQuoteV2 = 0;
        try {
            const result1 = await this.routerContractV1.methods.getAmountsOut(new BigNumber((10 ** obj.tokenDecimal).toString()).toString(), addresses).call();
            tokenQuoteV1 = new BigNumber(result1[1]).div(10 ** 18).toNumber();
        } catch (error) {
            //console.log(error)
        }
        try {
            const result2 = await this.routerContractV2.methods.getAmountsOut(new BigNumber((10 ** obj.tokenDecimal).toString()).toString(), addresses).call();
            tokenQuoteV2 = new BigNumber(result2[1]).div(10 ** 18).toNumber();
        } catch (error) {
            //console.log(error)
        }
        obj.priceV1 = tokenQuoteV1 / this.BUSDPrice;
        obj.priceV2 = tokenQuoteV2 / this.BUSDPrice;
        if (obj.exchange == undefined) {
            if (obj.priceV2 > obj.priceV1) {
                obj.exchange = "v2"
            } else {
                obj.exchange = "v1"
            }
        }
        if (Number.isNaN(obj.priceV1) && Number.isNaN(obj.priceV2)) {
            obj.oldPrice = 0;
            obj.valueInUsd = 0;
            obj.valueInBnb = 0;
            obj.realPrice = 0;
            obj.priceV1 = 0;
            obj.firstPrice = 0;
            obj.priceV2 = 0;
            obj.exchange = "v2"
            return;
        }

        if (obj.exchange == 'v1') {
            obj.valueInUsd = obj.balance * obj.priceV1
        } else {
            obj.valueInUsd = obj.balance * obj.priceV2
        }
        obj.valueInBnb = obj.valueInUsd / this.BNBPrice

        if (addAnimation != null) {
            addAnimation(obj)
        }
        if (removeAllAnimation != null) {
            removeAllAnimation(obj)
        }

        obj.oldBalance = obj.valueInUsd;
        if (obj.exchange == 'v1' && obj.priceV1 != 0) {
            obj.oldPrice = obj.priceV1
        } else {
            obj.oldPrice = obj.priceV2
        }

        try {
            let contractObject = await new this.web3.eth.Contract(this.getContractAbi(), obj.contractAddress);
            obj.circulatingSupply = await this.getSupply(contractObject, obj);
            obj.totalBurn = await this.getFullBurn(contractObject, obj);
            var difCap = obj.circulatingSupply - obj.totalBurn;
            obj.marketCap = difCap * obj.oldPrice;

        } catch (error) {
            obj.marketCap = 0;
        }

        if (obj.firstPrice == null || obj.firstPrice == undefined) {
            obj.firstPrice = obj.oldPrice;
        }

        console.log(obj)

    }

    getRouterContractV1 = async () => {
        if (this.routerContractV1 == null || this.currentRouterV1 != this.currentWeb3Provider) {
            this.currentRouterV1 = this.currentWeb3Provider;
            console.log("INITIALIZED ROUTER V1", this.currentRouterV1)
            this.routerContractV1 = await new this.web3.eth.Contract(this.getRouterAbi(), this.masterChefAddressV1);
            console.log(this.routerContractV1)
        }
        return this.routerContractV1;
    }

    getRouterContractV2 = async () => {
        if (this.routerContractV2 == null || this.currentRouterV2 != this.currentWeb3Provider) {
            this.currentRouterV2 = this.currentWeb3Provider;
            console.log("INITIALIZED ROUTER V2", this.currentRouterV2)
            this.routerContractV2 = await new this.web3.eth.Contract(this.getRouterAbi(), this.masterChefAddressV2);
            console.log(this.routerContractV2)
        }
        return this.routerContractV2;
    }

    getFactoryContractV1 = async () => {
        if (this.factoryContractV1 == null || this.currentFactoryV1 != this.currentWeb3Provider) {
            this.currentFactoryV1 = this.currentWeb3Provider;
            console.log("INITIALIZED FACTORY V1", this.currentFactoryV1)
            this.factoryContractV1 = await new this.web3.eth.Contract(this.getFactoryAbi(), this.factoryAddressV1);
            console.log(this.factoryContractV1)
        }
        return this.factoryContractV1;
    }

    getFactoryContractV2 = async () => {
        if (this.factoryContractV2 == null || this.currentFactoryV2 != this.currentWeb3Provider) {
            this.currentFactoryV2 = this.currentWeb3Provider;
            console.log("INITIALIZED FACTORY V2", this.currentFactoryV2)
            this.factoryContractV2 = await new this.web3.eth.Contract(this.getFactoryAbi(), this.factoryAddressV2);
            console.log(this.factoryContractV2)
        }
        return this.factoryContractV2;
    }

    getPastEvents = async (contractObject, block, contractWallet, transactions) => {
        if (this.pastEventBlock == 0) {
            this.pastEventBlock = block;
        }
        console.log("Block: " + this.pastEventBlock)
        console.log("---------------------------------//-----------------------------------")
        contractObject.getPastEvents('allEvents', {
            fromBlock: this.pastEventBlock,
            toBlock: 'latest'
        }, function (error, events) { }).then(events => {
            for (var ev in events) {
                if (this.pastEventsHashs.has(events[ev].transactionHash)) {
                    continue;
                }
                this.pastEventsHashs.add(events[ev].transactionHash);
                if (events[ev].blockNumber > block) {
                    this.pastEventBlock = events[ev].blockNumber;
                    this.pastEventBlock++;
                }
                this.web3.eth.getTransaction(events[ev].transactionHash)
                    .then(res => {
                        console.log("Transaction", res);
                        var trans = this.transformInTransaction(res, this.BNBPrice, contractWallet)
                        if (trans != null) {
                            transactions.unshift(trans);
                            $('#sort').click()
                        }
                    });
            }
        })
    }

    transformInTransaction(tx, BNBPrice, contractWallet) {
        var transaction = {}
        transaction["block"] = { "height": tx.blockNumber, "timestamp": { "time": Util.returnUTCDateNow() } }
        var method = abiDecoder.decodeMethod(tx.input);
        var to = tx.to;
        to = this.web3.utils.toChecksumAddress(to);
        transaction["to"] = to;


        console.log("preço do bnb", this.BNBPrice)
        console.log(method)
        if (method == undefined) {
            return null;
        }
        // compra
        if (method.name == "swapETHForExactTokens" || method.name == "swapExactETHForTokens" ||
            method.name == "swapExactETHForTokensSupportingFeeOnTransferTokens") {
            transaction["inputCurrency"] = {};
            transaction["inputCurrency"]["address"] = this.WBNB;
            transaction["inputCurrency"]["symbol"] = "WBNB";

            var valueInput = parseFloat(this.web3.utils.fromWei(tx.value));
            transaction["inputAmount"] = valueInput;
            transaction["inputAmountInUsd"] = valueInput * BNBPrice;

            transaction["outputCurrency"] = {};
            transaction["outputCurrency"]["address"] = contractWallet.contractAddress;
            transaction["outputCurrency"]["symbol"] = contractWallet.tokenSymbol;

            var valueOutput = BigNumber(method.params[0].value).div(10 ** contractWallet.tokenDecimal).toNumber()
            transaction["outputAmount"] = valueOutput;
            transaction["outputAmountInUsd"] = 0;

            transaction["transaction"] = {};
            transaction["transaction"]["hash"] = tx.hash;


            console.log("Transaction Buy", transaction);
            return transaction;

        }
        // venda
        if (method.name == "swapExactTokensForETH" || method.name == "swapTokensForExactETH"
            || method.name == "swapExactTokensForETHSupportingFeeOnTransferTokens") {

            transaction["inputCurrency"] = {};
            transaction["inputCurrency"]["address"] = contractWallet.contractAddress;
            transaction["inputCurrency"]["symbol"] = contractWallet.tokenSymbol;

            var valueInputSale = BigNumber(method.params[0].value).div(10 ** contractWallet.tokenDecimal).toNumber()
            transaction["inputAmount"] = valueInputSale;
            transaction["inputAmountInUsd"] = 0;

            transaction["outputCurrency"] = {};
            transaction["outputCurrency"]["address"] = this.WBNB;
            transaction["outputCurrency"]["symbol"] = "WBNB";

            var valueOutputSale = parseFloat(this.web3.utils.fromWei(method.params[1].value));
            transaction["outputAmount"] = valueOutputSale;
            transaction["outputAmountInUsd"] = valueOutputSale * BNBPrice;

            transaction["transaction"] = {};
            transaction["transaction"]["hash"] = tx.hash;
            console.log("Transaction Sell", transaction)
            return transaction;
        }

        if (method.name == "swapExactTokensForTokensSupportingFeeOnTransferTokens" || method.name == "swapExactTokensForTokens") {
            transaction["transaction"] = {};
            transaction["transaction"]["hash"] = tx.hash;

            var input = method.params[2].value[0];
            // venda
            if (input == contractWallet.contractAddress) {
                transaction["inputCurrency"] = {};
                transaction["inputCurrency"]["address"] = contractWallet.contractAddress;
                transaction["inputCurrency"]["symbol"] = contractWallet.tokenSymbol;

                var valueInputSale = BigNumber(method.params[0].value).div(10 ** contractWallet.tokenDecimal).toNumber()
                transaction["inputAmount"] = valueInputSale;
                transaction["inputAmountInUsd"] = 0;

                transaction["outputCurrency"] = {};
                transaction["outputCurrency"]["address"] = this.WBNB;
                transaction["outputCurrency"]["symbol"] = "WBNB";

                //descobrir valor
                var valueOutputSale = 0;
                if (to == this.masterChefAddressV1) {
                    valueOutputSale = valueInputSale * contractWallet.priceV1;
                } else {
                    valueOutputSale = valueInputSale * contractWallet.priceV2;
                }

                transaction["outputAmount"] = valueOutputSale / this.BNBPrice;
                transaction["outputAmountInUsd"] = valueOutputSale;
                console.log("Transaction Sell", transaction)
                return transaction;
            }
            // compra 
            else {
                transaction["outputCurrency"] = {};
                transaction["outputCurrency"]["address"] = contractWallet.contractAddress;
                transaction["outputCurrency"]["symbol"] = contractWallet.tokenSymbol;

                var valueOutputSaleSell = BigNumber(method.params[1].value).div(10 ** contractWallet.tokenDecimal).toNumber()
                transaction["outputAmount"] = valueOutputSaleSell;
                transaction["outputAmountInUsd"] = 0;

                transaction["inputCurrency"] = {};
                transaction["inputCurrency"]["address"] = this.WBNB;
                transaction["inputCurrency"]["symbol"] = "WBNB";


                var valueInputSaleSell = 0;
                if (to == this.masterChefAddressV1) {
                    valueInputSaleSell = valueOutputSaleSell * contractWallet.priceV1;
                } else {
                    valueInputSaleSell = valueOutputSaleSell * contractWallet.priceV2;
                }

                transaction["inputAmount"] = valueInputSaleSell / this.BNBPrice;
                transaction["inputAmountInUsd"] = valueInputSaleSell;
                console.log("Transaction Buy", transaction)
                return transaction;
            }
        }
        /**
         * Aqui no final depois de tudo eu printo os que não entraram em nada pra poder ver o que merda é
         */
        return null;
    }

    waitHere(transaction, method, tx, contractWallet) {
        if (method.name == "swapExactTokensForTokensSupportingFeeOnTransferTokens") {

            // o primeiro cara dentro do path é o method.params[2] é o pair trocado
            // method.params[2].value[1]
            var pair = method.params[2].value[1];
            pair = this.web3.utils.toChecksumAddress(pair)
            var to = tx.to;
            to = this.web3.utils.toChecksumAddress(to);
            if (pair != this.WBNB && pair != this.USDT && pair != this.BUSD) {
                return null;
            }
            var from = method.params[2].value[0];
            // existe essa função para compra por outro token, mas você precisaria calcular o preço desse token, então eu 
            // SIMPLESMENTE vou descarta-la
            if (from != contractWallet.contractAddress) {
                return null;
            }
            // se o par acima não for BNB, BUSD ou USDT, eu descarto
            // insiro com as infos de venda
            // preciso descobrir o preço de venda, pq o amountOut pode não ser do pair
            transaction["inputCurrency"] = {};
            transaction["inputCurrency"]["address"] = contractWallet.contractAddress;
            transaction["inputCurrency"]["symbol"] = contractWallet.tokenSymbol;

            transaction["transaction"] = {};
            transaction["transaction"]["hash"] = tx.hash;
            console.log("Transaction Sell", transaction)

            var valueInputSale = BigNumber(method.params[0].value).div(10 ** contractWallet.tokenDecimal).toNumber()
            transaction["inputAmount"] = valueInputSale;
            transaction["inputAmountInUsd"] = 0;

            //descobrir valor
            var valueOutputSale = 0;
            if (to == this.masterChefAddressV1) {
                valueOutputSale = valueInputSale * contractWallet.priceV1;
            } else {
                valueOutputSale = valueInputSale * contractWallet.priceV2;
            }

            if (pair == this.WBNB) {
                transaction["outputCurrency"] = {};
                transaction["outputCurrency"]["address"] = this.WBNB;
                transaction["outputCurrency"]["symbol"] = "WBNB";

                transaction["outputAmountInUsd"] = valueOutputSale;
                transaction["outputAmount"] = valueOutputSale / this.BNBPrice;

                console.log(transaction)
                return transaction;
            }

            if (pair == this.USDT) {
                transaction["outputCurrency"] = {};
                transaction["outputCurrency"]["address"] = this.USDT;
                transaction["outputCurrency"]["symbol"] = "USDT";

                transaction["outputAmount"] = valueOutputSale;
                transaction["outputAmountInUsd"] = valueOutputSale;
                console.log(transaction)
                return transaction;
            }
            if (pair == this.BUSD) {
                transaction["outputCurrency"] = {};
                transaction["outputCurrency"]["address"] = this.BUSD;
                transaction["outputCurrency"]["symbol"] = "BUSD";

                //descobrir valor
                transaction["outputAmount"] = valueOutputSale;
                transaction["outputAmountInUsd"] = valueOutputSale;
                console.log(transaction)
                return transaction;
            }
            console.log("swapExactTokensForTokensSupportingFeeOnTransferTokens NULLEI FORTE")
            return null;
        }
    }

    getContractAbi() {
        let statsAbi = [
            {
                inputs: [],
                name: 'totalFees',
                outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                stateMutability: 'view',
                type: 'function',
            },
            {
                inputs: [],
                name: 'decimals',
                outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
                stateMutability: 'view',
                type: 'function',
            },
            {
                inputs: [],
                name: 'name',
                outputs: [{ internalType: 'string', name: '', type: 'string' }],
                stateMutability: 'view',
                type: 'function',
            },
            {
                inputs: [],
                name: 'symbol',
                outputs: [{ internalType: 'string', name: '', type: 'string' }],
                stateMutability: 'view',
                type: 'function',
            },
            {
                inputs: [],
                name: 'totalSupply',
                outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                stateMutability: 'view',
                type: 'function',
            },
            {
                inputs: [],
                name: 'decimals',
                outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
                stateMutability: 'view',
                type: 'function',
            },
            {
                inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
                name: 'balanceOf',
                outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                stateMutability: 'view',
                type: 'function',
            },
            {
                inputs: [],
                name: "uniswapV2Pair",
                outputs: [{ "internalType": "address", "name": "", "type": "address" }],
                stateMutability: "view",
                type: "function"
            },
        ];

        return statsAbi;
    }

    getRouterAbi() {
        let routerAbi = [
            {
                inputs: [
                    {
                        internalType: 'uint256',
                        name: 'amountIn',
                        type: 'uint256',
                    },
                    {
                        internalType: 'address[]',
                        name: 'path',
                        type: 'address[]',
                    },
                ],
                name: 'getAmountsOut',
                outputs: [
                    {
                        internalType: 'uint256[]',
                        name: 'amounts',
                        type: 'uint256[]',
                    },
                ],
                stateMutability: 'view',
                type: 'function',
            },
            {
                inputs: [
                    {
                        internalType: 'address',
                        name: 'tokenA',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'tokenB',
                        type: 'address',
                    },
                ],
                name: 'getPair',
                outputs: [
                    {
                        internalType: 'address',
                        name: 'pair',
                        type: 'address',
                    },
                ],
                stateMutability: 'view',
                type: 'function',
            },
        ];
        return routerAbi;
    }

    getFactoryAbi() {
        let factoryAbi = [
            {
                inputs: [
                    {
                        internalType: 'address',
                        name: 'tokenA',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'tokenB',
                        type: 'address',
                    },
                ],
                name: 'getPair',
                outputs: [
                    {
                        internalType: 'address',
                        name: 'pair',
                        type: 'address',
                    },
                ],
                stateMutability: 'view',
                type: 'function',
            },
        ];
        return factoryAbi;
    }

    getUniSwapABI() {
        let uniswapABI = [
            {
                constant: true,
                inputs: [],
                name: "token0",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address"
                    }
                ],
                payable: false,
                stateMutability: "view",
                type: "function"
            },
            {
                constant: true,
                inputs: [],
                name: "token1",
                outputs: [
                    {
                        internalType: "address",
                        name: "",
                        type: "address"
                    }
                ],
                payable: false,
                stateMutability: "view",
                type: "function"
            },
            {
                constant: true,
                inputs: [],
                name: "getReserves",
                outputs: [
                    {
                        internalType: "uint112",
                        name: "_reserve0",
                        type: "uint112"
                    },
                    {
                        internalType: "uint112",
                        name: "_reserve1",
                        type: "uint112"
                    },
                    {
                        internalType: "uint32",
                        name: "_blockTimestampLast",
                        type: "uint32"
                    }
                ],
                payable: false,
                stateMutability: "view",
                type: "function"
            },
        ]
        return uniswapABI;
    }

    getMichAKABI() {
        let minABI = [
            // transfer
            {
                "constant": false,
                "inputs": [
                    {
                        "name": "_to",
                        "type": "address"
                    },
                    {
                        "name": "_value",
                        "type": "uint256"
                    }
                ],
                "name": "transfer",
                "outputs": [
                    {
                        "name": "",
                        "type": "bool"
                    }
                ],
                "type": "function"
            }
        ];

        return minABI;
    }
}