import { Injectable } from '@angular/core';

import { HttpService } from '../http.service';
import { catchError, map } from 'rxjs/operators';

import { User } from '../model/user';

@Injectable()
export class OnChainService {
    rootPath = "onchain";
    currentUser;

    constructor(private httpUrl: HttpService) {

    }

    startDepositRequest(txHash) {
        var path = "deposit"
        let bodyObj = { "transaction_hash": txHash };
        var bodyString = JSON.stringify(bodyObj)

        this.httpUrl.appendAuthorization();

        const url = `${this.httpUrl.transactionUrl}/${this.rootPath}/${path}`;
        console.log(url, bodyString)
        return this.httpUrl.http
            .post<any>(url, bodyString, { headers: this.httpUrl.headers })
            .pipe(map(response => response))
            .pipe(
                catchError(err => this.handleError(err))
            )
    }

    startWithdrawRequest(amount) {
        var path = "withdraw"
        let bodyObj = { "amount": amount };
        var bodyString = JSON.stringify(bodyObj)

        this.httpUrl.appendAuthorization();

        const url = `${this.httpUrl.transactionUrl}/${this.rootPath}/${path}`;
        console.log(url, bodyString)
        return this.httpUrl.http
            .post<any>(url, bodyString, { headers: this.httpUrl.headers })
            .pipe(map(response => response))
            .pipe(
                catchError(err => this.handleError(err))
            )
    }

    getLastWithdraw() {
        var path = "check_withdraw"

        this.httpUrl.appendAuthorization();

        const url = `${this.httpUrl.transactionUrl}/${this.rootPath}/${path}`;
        return this.httpUrl.http
            .get<any>(url, { headers: this.httpUrl.headers })
            .pipe(map(response => response))
            .pipe(
                catchError(err => this.handleError(err))
            )
    }

    handleError(error: any): Promise<any> {
        return Promise.resolve(null);
    }

}