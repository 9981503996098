import { Component, Input, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { MetaService } from '@ngx-meta/core';
import { pipe } from 'rxjs';

@Component({
    selector: 'not-found',
    templateUrl: 'not-found.component.html',
    styleUrls: ['./not-found.component.css']
})

export class NotFoundComponent {

    constructor() {

    }

    ngOnInit() {
    
        }

      

}