import { Component } from '@angular/core';


@Component({
    selector: 'sweet-modal',
    template: `
  <div (click)="onContainerClicked($event)" class="modal fade"  tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" [ngClass]="{'in': visibleAnimate}"
       [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0}">
       <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content sweet">
        <div class="modal-body">
          <ng-content select=".app-modal-body"></ng-content>
        </div>
        <div class="modal-footer">
          <ng-content select=".app-modal-footer"></ng-content>
        </div>
      </div>
    </div>
  </div>
  `,
    styleUrls: ['./sweet-modal.component.css']
})
export class SweetModalComponent {

    public visible = false;
    public visibleAnimate = false;

    constructor() {

     }

    public show(): void {
        this.visible = true;
        setTimeout(() => this.visibleAnimate = true, 100);
    }

    public hide(): void {
        this.visibleAnimate = false;
        setTimeout(() => this.visible = false, 300);
    }

    public onContainerClicked(event: MouseEvent): void {
        if ((<HTMLElement>event.target).classList.contains('modal')) {
            this.hide();
        }
    }
}
