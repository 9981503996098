import { Component, ViewChild, NgZone, Renderer, ElementRef } from '@angular/core';
import { Http } from '@angular/http';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'
import { Location, PopStateEvent } from "@angular/common";
import { Meta } from '@angular/platform-browser';
import { HttpService } from './http.service';
import { ModalBorderComponent } from '../app/component/modal-border/modal-border.component'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('modal1', { static: true }) errorModal: ModalBorderComponent;


  title = 'app';

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  rocketzAccounts;
  script;
  isMuted = true;


  constructor(private router: Router, private location: Location, private meta: Meta, public httpService: HttpService) {
  }


  ngOnInit() {

  }

  ngAfterViewInit() {
    this.httpService.putInstaceOfModal(this.errorModal)
  }
}
