import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";



@Injectable()
export class AuthGuard implements CanActivate {
  user;

  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (localStorage.getItem('secret')) {
      if (JSON.parse(localStorage.getItem('secret')).session_token != undefined) {
        return true;
      }
    }
    this.router.navigate(['/login'])
    return false;
  }

}
