import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import * as $ from 'jquery';
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent {
    menuOn;
    i;


    constructor(private router: Router, private fb: FormBuilder) {

    }

    menu() {
      if($('.submenu').hasClass("appear")) {
        $('.submenu').removeClass("appear")
      } else {
        $('.submenu').addClass("appear")
      }
    }
  

    activeHeader() {
        $('.side-nav').addClass("menuActive");
        this.menuOn = true;
    }

    removeHeader() {
        if ($('.side-nav').hasClass("menuActive")) {
          $('.side-nav').removeClass("menuActive");
          this.menuOn = false;
        }
      }

      requestAudit() {
        console.log("pediua udit");
        $("#fall-link").attr("href", "javascript:void($crisp.push(['do', 'message:send', ['text', 'Hello, I would like to know more about the audit.']]))");
        $("#btn").click();
        $("#fall-link").attr("href", "javascript:void($crisp.push(['do', 'chat:open']))");
        $("#btn").click();
      }


}
