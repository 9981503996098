import { Injectable } from "@angular/core";

@Injectable()
export class UtilsService {

    constructor() { }

    randomIntFromInterval(min, max) { 
        return Math.floor(Math.random() * (max - min + 1) + min)
      }

    internalReplaceAll(phrase, before, after) {
        if (phrase == undefined || phrase == null) {
            return;
        }
        // Count how many characters in string
        let letterCount = phrase.replace(before, '').length;

        for (var i = 0; i < letterCount; i++) {
            phrase = phrase.replace(before, after)
        }
        return phrase;
    }

    removeBannedChart(phrase) {
        phrase = this.internalReplaceAll(phrase, "<", "");
        phrase = this.internalReplaceAll(phrase, ">", "");
        phrase = this.internalReplaceAll(phrase, "$", "");
        phrase = this.internalReplaceAll(phrase, "'", "");
        phrase = this.internalReplaceAll(phrase, "*", "");
        return phrase;
    }

    formatBigToFixed(price) {
        if (price == 0 || price == undefined) {
            return 0;
        }

        var num = price.toFixed(15);
        if (!num.startsWith('0.0')) {
            return price.toFixed(2)
        }
        var countZero = num.replace("0.", "")
        var totalOfZeros = 0;
        for (var i = 0; i < countZero.length; i++) {
            var character = countZero.charAt(i);
            if (character == "0") {
                totalOfZeros++;
            } else {
                break;
            }
        }
        return price.toFixed(totalOfZeros + 4);
    }

    calcChartPriceLabel(price) {
        if (price == 0 || price == undefined) {
            return 0;
        }

        var num = price.toFixed(15);
        if (!num.startsWith('0.0')) {
            return "n2"
        }
        var countZero = num.replace("0.", "")
        var totalOfZeros = 0;
        for (var i = 0; i < countZero.length; i++) {
            var character = countZero.charAt(i);
            if (character == "0") {
                totalOfZeros++;
            } else {
                break;
            }
        }
        return "n" + (totalOfZeros + 3);
    }

    nFormatter(num, digits) {
        var si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "K" },
            { value: 1E6, symbol: " MILLION" },
            { value: 1E9, symbol: " BILLION" },
            { value: 1E12, symbol: " QUADRILLION" },
            { value: 1E15, symbol: " QUINTRILLION" },
            { value: 1E18, symbol: " SEXTILLION" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }

    returnUTCDateNow() {
        var d = new Date();
        var secondsString = "";
        var seconds = d.getUTCSeconds();
        secondsString = "" + seconds
        if (seconds < 10) {
            secondsString = "0" + seconds;
        }
        var minuteString = "";
        var minute = d.getUTCMinutes();
        minuteString = "" + minute
        if (minute < 10) {
            minuteString = "0" + minute;
        }
        var hoursString = "";
        var hours = d.getUTCHours();
        hoursString = "" + hours
        if (hours < 10) {
            hoursString = "0" + hours;
        }
        var monthString = "";
        var month = d.getUTCMonth() + 1
        monthString = "" + month
        if (month < 10) {
            monthString = "0" + month
        }
        var fullDate = d.getUTCFullYear() + "-" + monthString + "-" + d.getUTCDate() + " " + hoursString + ":" + minuteString
            + ":" + secondsString
        return fullDate;
    }

}